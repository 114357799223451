import axios from "axios";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../interceptor/axiosInstance";
import "./LevelPage.css";
import { Modal } from "react-bootstrap";

const MyLevelComponent = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [selectedCard, setSelectedCard] = useState(null);
  const [investmentAmount, setInvestmentAmount] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [nonWorkingRewards, setNonWorkingRewards] = useState([]);
  const [userReward, setUserReward] = useState(null);
  const [pendingBranches, setPendingBranches] = useState([]);
  const [paymentPending, setPaymentPending] = useState([]);

  useEffect(() => {
    const fetchRewardsData = async () => {
      try {
        const [nonWorkingResponse, userRewardResponse] = await Promise.all([
          axios.get(`${API_URL}/account/member/ship/reward/`),
          axiosInstance.get(`${API_URL}/account/get/reward/match/`),
        ]);

        setNonWorkingRewards(nonWorkingResponse.data);
        setUserReward(userRewardResponse.data);
        setPendingBranches(userRewardResponse.data.pending_branch || []);
        setPaymentPending(userRewardResponse.data.payment_pending || []);
      } catch (error) {
        console.error("Error fetching rewards data:", error);
      }
    };

    fetchRewardsData();
  }, [API_URL]);

  const handleCardClick = (index) => {
    const reward = nonWorkingRewards[index];
    setSelectedCard(index);
    setInvestmentAmount(reward?.reward_price || 0);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCard(null);
    setInvestmentAmount(null);
  };

  const renderRewards = () => {
    return nonWorkingRewards.map((reward, index) => {
      const isMatched = userReward?.data?.member_ship_reward?.includes(reward.id);
      const isPassed = reward.passed;

      return (
        <div
          key={reward.id}
          className={`col-md-2 m-2 text-center rounded p-4 ${isMatched ? "bg-success" : "bg-danger"}`}
          onClick={() => handleCardClick(index)}
          style={{
            cursor: "pointer",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            transform: selectedCard === index ? "scale(1.1)" : "scale(1)",
            zIndex: selectedCard === index ? 1 : 0,
            position: selectedCard === index ? "relative" : "static",
          }}
        >
          <div className="icon-container mb-2">
            <img src="../../images/reward_medal.png" alt="Reward Icon" className="reward-icon" />
          </div>
          <div className="reward-info text-white">
            <h2 className="h5">{reward.arrange}</h2>
            <h3 className="h6">{reward.plan_name}</h3>
            <h4>
              {reward.reward_price ? `Rs. ${reward.reward_price}` : ""} {reward.details || ""}
            </h4>
          </div>
        </div>
      );
    });
  };

  const renderTableRows = (data, columns) => {
    return data.map((item, index) => (
      <tr key={index}>
        {columns.map((col, colIndex) => (
          <td key={colIndex}>{col(item)}</td>
        ))}
      </tr>
    ));
  };

  return (
    <div className="container my-level-container margin-top-153">
      <header className="text-center mb-4">
        <h1 className="display-4">Your Level</h1>
      </header>

      <div className="row justify-content-center my-4 position-relative px-5">
        {renderRewards()}
      </div>

      <section className="pending-branch-section my-4">
        <h2>Pending Branches</h2>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Parent User</th>
              <th>Branch Name</th>
            </tr>
          </thead>
          <tbody>
            {renderTableRows(pendingBranches, [
              (branch) => branch.parent_user,
              (branch) => branch.branch_name,
            ])}
          </tbody>
        </table>
      </section>

      <section className="payment-pending-section my-4">
        <h2>Payment Pending</h2>
        <table className="table table-striped table-responsive">
          <thead>
            <tr>
              <th>Parent User</th>
              <th>Pending User</th>
              <th>Joined Date</th>
              <th>Payment Pending</th>
              <th>Payment Status</th>
              <th>Branch Name</th>
            </tr>
          </thead>
          <tbody>
            {renderTableRows(paymentPending, [
              (payment) => payment.parent_user,
              (payment) => payment.pending_user,
              (payment) => new Date(payment.joined_date).toLocaleDateString(),
              (payment) => (payment.payment_pending ? "Yes" : "No"),
              (payment) => (payment.payment_status ? "Completed" : "Pending"),
              (payment) => payment.branch_name,
            ])}
          </tbody>
        </table>
      </section>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{nonWorkingRewards[selectedCard]?.plan_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCard !== null && (
            <>
              <img
                src="../../images/reward_medal.png"
                alt="Reward Icon"
                className="reward-icon mb-2"
              />
              <h2>{nonWorkingRewards[selectedCard].arrange}</h2>
              {nonWorkingRewards[selectedCard].reward_price && (
                <h4>Rs. {nonWorkingRewards[selectedCard].reward_price}</h4>
              )}
              {nonWorkingRewards[selectedCard].details && (
                <div
                  className="details-section text-secondary"
                  dangerouslySetInnerHTML={{
                    __html: nonWorkingRewards[selectedCard].details,
                  }}
                />
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MyLevelComponent;

import React, { useEffect, useState } from "react";
import LotteryComponent from "../mlm/Components/LotteryPage/LotteryComponent";
import WinningListCard from "../mlm/Components/LotteryPage/WinningListCard";
import Header from "../Components/header/header";

import Footer from "../Components/footer/footer";
import Loader from "../Components/Loader";

const CrowdFunding = () => {
    const [loading, setLoading] = useState(false);
  return (
    <div>
      {loading ? <Loader /> : null}
      <Header />
      <main id="content">
        <section className="margin-top-120">
          <LotteryComponent />
          <br />

          <WinningListCard />
        </section>
      </main>

      <div className="position-fixed pos-fixed-bottom-right p-6 z-index-10">
        <a
          href="#"
          className="gtf-back-to-top text-white bg-blue hover-saffron sidebar-list-item bg shadow p-0 w-52px h-52 rounded-circle fs-20 d-flex align-items-center justify-content-center add-opacity-cstm"
          title="Back To Top"
        >
          <i className="fal fa-arrow-up"></i>
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default CrowdFunding;

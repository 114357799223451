import React, { useEffect, useState } from "react";
import Header from "../../Components/header/header";
import Footer from "../../Components/footer/footer";
import axios from "axios";

const PrivacyPolicy = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [privacyPolicy, setPrivacyPolicy] = useState(null);

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      try {
        const response = await axios.get(`${API_URL}/blog/privacy/policy/`);
        setPrivacyPolicy(response.data);
      } catch (error) {
        console.error("Error fetching Privacy Policy", error);
      }
    };

    fetchPrivacyPolicy();
  }, [API_URL]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Header />
      <br />
      <br />

      <br />

      <br />

      <br />


      <br />
      <div style={{ marginTop: "100px" }}>
        <main id="content" className="mt-100">
          <section className="bg-patten-03 bg-gray-01 pb-13" data-animated-id="3">
            <div className="container">
              <div className="card border-0 mt-n13 z-index-3 mb-12">
                <div className="card-body p-6 px-lg-14 py-lg-10">
                  <p className="text-uppercase text-blue mb-5 fw-600 text-center fs-22">
                    Privacy & Policy
                  </p>
                  <div className="details-section text-secondary">
                    {privacyPolicy ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: privacyPolicy.description,
                        }}
                      />
                    ) : (
                      <p className="text-center text-black fs-16 fm-nunito">
                        Loading Privacy Policy...
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;

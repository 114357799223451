import React, { useEffect, useState } from "react";
import axios from "axios";

function LearningComponents() {
  const API_URL = process.env.REACT_APP_API_URL;

  const [data, setData] = useState([]);

  // Fetch images from the API
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${API_URL}/blog/business/plan/image/`);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };
    fetchImages();
  }, [API_URL]);


  return (
    <>
      <div className="container my-4 margin-top-109">
        <h1 className="text-center text-red mb-4">Business Plan</h1>
        <hr />
        <div className="">
        <div dangerouslySetInnerHTML={{ __html: data.desc || "No description available" }} />

        </div>
      </div>
    </>
  );
}

export default LearningComponents;

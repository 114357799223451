import React, { useEffect, useState } from "react";
import { BsInstagram,BsLinkedin, BsSkype, BsTwitter, BsYoutube } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';



const Footer = ({searchAddress}) => {

    return (
        <>
            <footer className="bg-dark pt-8 pb-6 footer text-muted">
                <div className="container container-xxl">
                    <div className="mb-6 mb-md-0 footer-icon">
                            
                            <div className="lh-26 font-weight-500">
                                
                            </div>
                        </div>
                    <div className="row footer-grid">
                        <div className="col-md-4 col-lg-4 mb-6 mb-md-0 footer-subscribe">
                            <h4 className="text-white fs-16 my-4 font-weight-500">Our Services</h4>
                            <ul className="list-group list-group-flush list-group-no-border">
                                <li className="list-group-item bg-transparent p-0">
                                    <a className="text-muted lh-26 font-weight-500 hover-white">Crowd Funding</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a  className="text-muted lh-26 font-weight-500 hover-white" >Membership</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a  className="text-muted lh-26 font-weight-500 hover-white" >Non Working</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a  className="text-muted lh-26 font-weight-500 hover-white" >Level</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a  className="text-muted lh-26 font-weight-500 hover-white" >Donation(partner)</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4 col-lg-4 mb-6 mb-md-0 footer-subscribe">
                            <h4 className="text-white fs-16 my-4 font-weight-500">Quick links</h4>
                            <ul className="list-group list-group-flush list-group-no-border">
                                <li className="list-group-item bg-transparent p-0">
                                    <a href="/about-us" className="text-muted lh-26 font-weight-500 hover-white">About</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a href="/contact-us" className="text-muted lh-26 font-weight-500 hover-white">Contact</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a href="/mlm/crowdfunding" className="text-muted lh-26 font-weight-500 hover-white">Result</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a href="/blog-list" className="text-muted lh-26 hover-white font-weight-500">Blog</a>
                                </li>
                              
                            </ul>
                        </div>

                        <div className="col-md-4 col-lg-4 mb-6 mb-md-0 footer-subscribe">
                       
                        
                              <h4 className="text-white fs-16 my-4 font-weight-500">We Help </h4>
                            <p className="font-weight-500 text-muted lh-184">Never miss to chances </p>
                       
                            
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item mr-0">
                                    <a href="https://x.com/AimTime0fficial" className="text-white opacity-3 fs-25 px-4 opacity-hover-10">
                                        <BsTwitter />
                                    </a>
                                </li>
                                <li className="list-inline-item mr-0">
                                    <a href="https://www.facebook.com/profile.php?id=100064010889081/" className="text-white opacity-3 fs-25 px-4 opacity-hover-10">
                                        <FaFacebookF /></a>
                                </li>
                                <li className="list-inline-item mr-0">
                                    <a href="https://www.instagram.com/aimtimemarketing/" className="text-white opacity-3 fs-25 px-4 opacity-hover-10">
                                        <BsInstagram /></a>
                                </li>
                                <li className="list-inline-item mr-0">
                                    <a href="https://www.youtube.com/@aimtimebusiness" className="text-white opacity-3 fs-25 px-4 opacity-hover-10">
                                        <BsYoutube /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="mt-0 mt-md-10 row">
                        <ul className="list-inline mb-0 col-md-4 mr-auto">
                            <li className="list-inline-item mr-4">
                                <a href="/mlm/term-condition/" className="text-muted lh-26 font-weight-500 hover-white">Terms of Use</a>
                            </li>
                            <li className="list-inline-item">
                                <a href="/mlm/privacy-policy/" className="text-muted lh-26 font-weight-500 hover-white">Privacy Policy</a>
                            </li>
                            <li className="list-inline-item">
                                <a href="/mlm/desclaimer/" className="text-muted lh-26 font-weight-500 hover-white">Disclaimer</a>
                            </li>
                        </ul>
                        <p className="col-md-auto mb-0 text-muted">
                            © 2022 AimTimeBusiness. All Rights Reserved
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import axiosInstance from '../../../interceptor/axiosInstance';
import { Modal } from 'react-bootstrap';

const NonWorkingComponent = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [showModal, setShowModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [nonWorkingRewards, setNonWorkingRewards] = useState([]);
  const [userReward, setUserReward] = useState(null);

  useEffect(() => {
    const fetchNonWorkingRewards = async () => {
      try {
        const response = await axios.get(`${API_URL}/account/non/working/list/`);
        setNonWorkingRewards(response.data);
      } catch (error) {
        console.error('Error fetching non-working rewards:', error);
      }
    };

    const fetchUserReward = async () => {
      try {
        const response = await axiosInstance.get(`${API_URL}/account/get/non/reward/match/`);
        setUserReward(response.data);
      } catch (error) {
        console.error('Error fetching user reward:', error);
      }
    };

    fetchNonWorkingRewards();
    fetchUserReward();
  }, [API_URL]);

  const handleCardClick = (index) => {
    setSelectedCard(index);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCard(null);
  };

  return (
    <div className="container my-level-container margin-top-153">
      <header className="text-center mb-4">
        <h1 className="display-4">Your Non-Working Level</h1>
      </header>

      <div className="row justify-content-center px-5">
        {nonWorkingRewards.map((reward, index) => {
          const isMatched = userReward?.data?.non_working_reward?.includes(reward.id);

          return (
            <div
              key={reward.id}
              className={`col-md-2 m-2 text-center rounded p-4 ${isMatched ? 'bg-success' : 'bg-danger'}`}
              onClick={() => handleCardClick(index)}
              style={{
                cursor: 'pointer',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                transform: selectedCard === index ? 'scale(1)' : 'scale(0.9)',
                zIndex: selectedCard === index ? 1 : 0,
                position: selectedCard === index ? 'relative' : 'static',
              }}
            >
              <div className="icon-container mb-2">
                <img src="../../images/reward_medal.png" alt="Reward Icon" className="reward-icon" />
              </div>
              <div className="reward-info text-white">
                <h2 className="h5">{reward.arrange}</h2>
                <h3 className="h6">{reward.plan_name}</h3>
                {reward.reward_price && <p>Rs. {reward.reward_price}</p>}
                <p>{reward.details || ''}</p>
              </div>
            </div>
          );
        })}
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedCard !== null && nonWorkingRewards[selectedCard]?.plan_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCard !== null && (
            <>
              <img
                src="../../images/reward_medal.png"
                alt="Reward Icon"
                className="reward-icon mb-2"
              />
              <h2>{nonWorkingRewards[selectedCard].arrange}</h2>
              {nonWorkingRewards[selectedCard].reward_price && (
                <h4>Rs. {nonWorkingRewards[selectedCard].reward_price}</h4>
              )}
              {nonWorkingRewards[selectedCard].details && (
                <div
                  className="details-section text-secondary"
                  dangerouslySetInnerHTML={{ __html: nonWorkingRewards[selectedCard].details }}
                />
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NonWorkingComponent;

import React, { useEffect, useState } from "react";
import Header from "../../Components/header/header";
import Footer from "../../Components/footer/footer";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useLocation, useNavigate } from "react-router-dom";

const AboutUs = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
},[]);

const Redirect = (e) => {
    // console.log(homeSearchData,'homeSearchData245');
    navigate('/propertylist', {state: {footerType:"AllProperty"}})
}
    return (
        <>
            <Header />
            <main id="content " className="mt-100">
                <section className="mb-10">
                {/* style={{ backgroundImage: `url(${BackLineImage})` }} */}
                    <div className="container container-xxl">
                        <div className=" py-11 ">
                            <div className="row">

                                <div className="col-md-6 px-8">
                                    <AnimationOnScroll animateIn="animate__fadeInLeft">
                                        <p className="letter-spacing-263 text-uppercase text-blue mb-3 font-weight-500">About Us</p>
                                        <h2 className=" text-heading mb-3 font-32 ">The investment Service Provide <br />investment Your valuable time and amount then get back extra.</h2>
                                        <p className=" text-black fs-17 fm-nunito text-justify">AimTime Business is a pioneering online platform dedicated to providing individuals with the opportunity to achieve financial independence through passive income. Our innovative approach combines the simplicity of investment with the excitement of a Crowd Funding, offering a unique and rewarding experience.</p>
                                        <p className=" text-black fs-17 fm-nunito text-justify">Ready to start earning passive income? Join AimTime Business and take the first step towards financial freedom. Our dedicated support team is always available to assist you and answer your questions.</p>

                                    </AnimationOnScroll>
                                </div>

                                <div className="col-6 ">
                                    <AnimationOnScroll animateIn="animate__fadeInRight">
                                        <div className="about-img-wrap">
                                            <img className="about-one bg-dark" src="images/about-new.png" />
                                           {/*  <img className="about-two" src="images/uncleji.png" /> */}
                                        </div>
                                    </AnimationOnScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="bg-patten-03 bg-gray-01 pb-13" data-animated-id="3">
                    <div className="container">
                        <div className="card border-0 mt-n13 z-index-3 mb-12">
                            <div className="card-body p-6 px-lg-14 py-lg-10">
                                <p className=" text-uppercase text-blue mb-5 fw-600 text-center fs-22">WHO WE ARE</p>
                                <h2 className="text-heading mb-4 fs-20  text-center lh-22 px-6">Our vision is to create a world where everyone has the power to achieve their financial goals without the constraints of traditional employment. We strive to be the leading platform for passive income, empowering individuals to live their dreams and build a better future.</h2>
<p className="text-center text-black fs-16 fm-nunito">
Our mission is to provide a secure, transparent, and user-friendly platform for individuals to invest and earn passive income. We are committed to fostering a community of like-minded individuals who share our passion for financial freedom. Through our innovative Crowd Funding and exceptional customer support, we aim to exceed the expectations of our members and deliver unparalleled value.</p>
                            </div>
                        </div>
                      
                    </div>
                </section>
               

            </main>
            <Footer />
        </>
    )
}
export default AboutUs;




import React, { useEffect, useState } from "react";
import Header from "../../Components/header/header";
import BannerImage from "../../../src/images/aimlanding";
import BannerHome from "../../../src/images/mlmHome.jpeg";
// import BackImage from "../../../src/images/about-shape.png";
// import BackInImage from "../../../src/images/shape-1.png";
// import BackLineImage from "../../../src/images/bg_circle.png";
import Footer from "../../Components/footer/footer";
import Slider from "react-slick/lib/slider";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useLocation, useNavigate } from "react-router-dom";
import SearchLocationInput from "../../Components/Map/SearchLocationInput";
import Loader from "../../Components/Loader";
import CountUp from "react-countup";
import axios from "axios";
import "@fortawesome/fontawesome-free/css/all.min.css";

const LandingPage = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const { state } = useLocation();
  const [loading, setLoading] = useState(false);

  const [reviews, setReviews] = useState([]);

  // Fetch reviews from the API
  useEffect(() => {
    axios
      .get(`${API_URL}/lottery/review/list/`)
      .then((response) => {
        setReviews(response.data);
      })
      .catch((error) => {
        console.error("Error fetching reviews:", error);
      });
  }, []);

  var settings = {
    dots: true,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  var Agents = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        Agents: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        Agents: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        Agents: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: false,
        },
      },
      {
        breakpoint: 320,
        Agents: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: false,
        },
      },
    ],
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <Header verifyAccount={state?.fromVarification} />
      <main id="content">
        <section className="margin-top-109">
          <img
            src={BannerHome}
            alt="aim time"
            style={{ width: "100%", height: "auto", display: "block" }}
          />
        </section>
        <section className="">
          <img
            src={BannerImage}
            alt="aim time"
            style={{ width: "100%", height: "auto", display: "block" }}
          />
        </section>
       
        <section className="bg-fade">
          {/* style={{ backgroundImage: `url(${BackLineImage})` }} */}

          <div className="container container-xxl">
            <div className=" ">
              <div className="row flex-direction-column ">
                <div className="col-lg-12">
                  <AnimationOnScroll animateIn="fadeInUp">
                    <div className="text-center py-10 paddingTopBottom-re" >
                      <h2 className="display-2 mb-1 text-saffron fs-30 fw-600 ">
                        ADDRESS
                      </h2>
                      <p className="text-black fs-32 fm-nunito fw-600 ">
                        Barsa Sheikhpura, Bihar 811105
                      </p>
                      <h2>
                      <a className="text-heading fm-poppins fs-22" href="tel:+91 7858 006 672"> <i className="fas fa-phone" style={{color:"#529BE8"}}></i>&nbsp; 7858 006 672</a>
                      </h2>
                      <h2>
                      <a  className="text-heading fm-poppins fs-22" href="https://api.whatsapp.com/send/?phone=%2B917858006672&text&type=phone_number&app_absent=0"><i className="fab fa-whatsapp" style={{ color: "green" }}></i>&nbsp; 7858 006 672</a>
                      </h2>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" pb-lg-10">
          <div className="main-section-couter-part counter-section">
            <div className="container-fluid py-5 px-10 padding-set-left-right">
              <div className="row">
                <div className="col-lg-6 txa-center text-count ">
                  <div className="row">
                    <div className="col-lg-3 content-algin">
                      <img src="images/certificate.png" />
                    </div>
                    <div className="col-lg-9 saparator-btw">
                      <h1 className="">
                        <CountUp
                          delay={1}
                          enableScrollSpy={true}
                          end={15}
                          className="h1-counter"
                        />
                        +
                      </h1>
                      <span className="re-font">Years of Experience</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 txa-center text-count ">
                  <div className="row">
                    <div className="col-lg-3 content-algin">
                      <img src="images/building-icon.png" />
                    </div>
                    <div className="col-lg-9 saparator-btw">
                      <h1>
                        <CountUp
                          delay={1}
                          enableScrollSpy={true}
                          end={1000}
                          className="h1-counter"
                        />
                        +
                      </h1>
                      <span>Donation</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 txa-center text-count ">
                  <div className="row">
                    <div className="col-lg-3 content-algin">
                      <img src="images/manager.png" />
                    </div>
                    <div className="col-lg-9 saparator-btw">
                      <h1>
                        <CountUp
                          delay={1}
                          enableScrollSpy={true}
                          end={200}
                          className="h1-counter"
                        />
                        +
                      </h1>
                      <span>Agents</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 txa-center text-count ">
                  <div className="row">
                    <div className="col-lg-3 content-algin">
                      <img src="images/sold-out.png" />
                    </div>
                    <div className="col-lg-9">
                      <h1>
                        <CountUp
                          delay={1}
                          enableScrollSpy={true}
                          end={100}
                          className="h1-counter"
                        />
                        k+
                      </h1>
                      <span>Happy User</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section
          className="d-flex flex-column"
          // style={{
          //   backgroundImage: `url(images/aimtimebusinessbody1.jpg)`,
          //   backgroundSize: "cover",
          //   backgroundPosition: "center",
          // }}
        >
          <div className="d-flex align-items-center custom-vh-100  mr-bm-sr">
          
            <div className="col-5">

              <AnimationOnScroll animateIn="animate__zoomIn">
                <div className="all-center">
                  <a href="#" className="all-round">
                 
                  </a>
                </div>
              </AnimationOnScroll>
            </div>
            <h1 
  className="text-color-red" 
  style={{ color: "red", fontWeight: "bold", margin: "-123px 25px -590px 35px", textAlign: "right" }}
>
  Address:- Barsa Sheikhpura, Bihar 811105
</h1>

          </div>
        </section> */}

        {/* <center>
  <h1 
    className="text-color-red" 
    style={{ 
      color: "red", 
      fontWeight: "bold", 
      margin: "20px 0", 
      display: "flex", 
      alignItems: "center", 
      justifyContent: "center", 
      gap: "10px" 
    }}
  >
    Helpline No: <a href="tel:+91 7858 006 672"><i className="fas fa-phone" style={{ marginRight: "10px" }}></i></a>7858 006 672
    <a href="https://api.whatsapp.com/send/?phone=%2B917858006672&text&type=phone_number&app_absent=0"><i className="fab fa-whatsapp" style={{ marginLeft: "10px", color: "green" }}></i></a>
  </h1>
</center> */}

        {/* <section>
        <div className="d-flex align-items-center  mr-bm-sr">

            <div className="ml-2 mt-10 col bg-fade op-7 round-5 margin-top-0 ">
              
              <AnimationOnScroll
                className="w-100"
                animateIn="animate__fadeInLeft "
              >
                <div className=" px-5 py-8 add-paddings-mobile">
                  <h2 className="text-black display-2  fs-42 fw-600 text-center">
                    <img
                      className="landing-fevicon"
                      src="images/aimtimebusiness.png"
                    />
                    <span className="text-saffron">No.1 Platform for</span>{" "}
                    <br />
                    <span className="text-blue">
                      Multi Level Marketing{" "}
                    </span>{" "}
                    <br />
                    Donate To Make Future Brighter
                  </h2>
                  <p className="text-center text-black fs-17 fm-nunito">
                    We are always with you if you have face any issues!!
                  </p>
                  <form className="d-flex">
                    <div className="position-relative w-100">
                      <div className="in-fr">
                        <SearchLocationInput />
                      </div>
                    </div>
                  </form>
                </div>
              </AnimationOnScroll>
            </div>
            
          </div>

        </section> */}

        {/* add counter section */}

        <section>
          <div className="bg-gray-02 py-lg-13 pt-11 pb-6 padding-top-bottom-50">
            <div className="container container-xxl">
              <div className="row">
                <div className="col-lg-4 pr-xl-13" data-animate="fadeInLeft">
                  <AnimationOnScroll animateIn="animate__fadeInLeft">
                    <h2 className="text-heading lh-1625 font-size-24">
                      Explore <br />
                      by Our Services
                    </h2>
                    <span className="heading-divider divider-bottom"></span>
                    <p className="mb-6 fm-nunito fs-17">
                    Maximize your return according to your donation and
                     working experience. never miss a deal.            
                     Golden opportunity to grow your money!
                    </p>
                    {/* <a className="btn btn-lg text-heading btn-accent">
                      Our Services
                      <i className="far fa-long-arrow-right ml-1"></i>
                    </a> */}
                  </AnimationOnScroll>
                </div>

                <div className="col-lg-8" data-animate="fadeInRight">
                  <AnimationOnScroll animateIn="animate__fadeInRight">
                    <div
                      className="slick-slider arrow-haft-inner custom-arrow-xxl-hide mx-0 opacity-1"
                      data-slick-options='{"slidesToShow": 4, "autoplay":true,"dots":false,"responsive":[{"breakpoint": 1200,"settings": {"slidesToShow":3,"arrows":false}},{"breakpoint": 992,"settings": {"slidesToShow":3,"arrows":false}},{"breakpoint": 768,"settings": {"slidesToShow": 3,"arrows":false,"autoplay":true}},{"breakpoint": 576,"settings": {"slidesToShow": 2,"arrows":false,"autoplay":true}}]}'
                    >
                      <Slider {...settings}>
                        {/* slider first */}
                        <div className="box px-0 py-6 wdt-193">
                          <a className="card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-sidebar-list-item text-decoration-none">
                            <img
                              src="images/office-building.png"
                              className="card-img-top"
                              alt="Apartment"
                              style={{ width: "140px" }}
                            />
                            <div className="card-body px-0 pt-5 pb-0">
                              <h4 className="card-title fs-16 lh-2 text-dark mb-0">
                              Product sell and booking income
                              </h4>
                            </div>
                          </a>
                        </div>

                        {/* slider two */}
                        <div className="box px-0 py-6 wdt-193">
                          <a className="card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-sidebar-list-item text-decoration-none">
                            <img
                              src="images/store.png"
                              className="card-img-top"
                              alt="House"
                              style={{ width: "140px" }}
                            />
                            <div className="card-body px-0 pt-5 pb-0">
                              <h4 className="card-title fs-16 lh-2 text-dark mb-0">
                              Non working income
                              </h4>
                            </div>
                          </a>
                        </div>

                        {/* slider three */}
                        <div className="box px-0 py-6 wdt-193">
                          <a className="card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-sidebar-list-item text-decoration-none">
                            <img
                              src="images/architect.png"
                              className="card-img-top"
                              alt="Office"
                              style={{ width: "140px" }}
                            />
                            <div className="card-body px-0 pt-5 pb-0">
                              <h4 className="card-title fs-16 lh-2 text-dark mb-0">
                                Donation
                              </h4>
                            </div>
                          </a>
                        </div>

                        {/* fourth slider */}
                        <div className="box px-0 py-6 wdt-193">
                          <a className="card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-sidebar-list-item text-decoration-none">
                            <img
                              src="images/factory.png"
                              className="card-img-top"
                              alt="Villa"
                              style={{ width: "140px" }}
                            />
                            <div className="card-body px-0 pt-5 pb-0">
                              <h4 className="card-title fs-16 lh-2 text-dark mb-0">
                                Donation With Company Partner
                              </h4>
                            </div>
                          </a>
                        </div>

                        {/* fifth slider */}
                        <div className="box px-0 py-6 wdt-193">
                          <a className="card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-sidebar-list-item text-decoration-none">
                            <img
                              src="images/cafe.png"
                              className="card-img-top"
                              alt="Apartment"
                              style={{ width: "140px" }}
                            />
                            <div className="card-body px-0 pt-5 pb-0">
                              <h4 className="card-title fs-16 lh-2 text-dark mb-0">
                              Leadership reward
                              </h4>
                            </div>
                          </a>
                        </div>
                        {/* sixth slider */}
                        <div className="box px-0 py-6 wdt-193">
                          <a className="card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-sidebar-list-item text-decoration-none">
                            <img
                              src="images/hospital.png"
                              className="card-img-top"
                              alt="Apartment"
                              style={{ width: "140px" }}
                            />
                            <div className="card-body px-0 pt-5 pb-0">
                              <h4 className="card-title fs-16 lh-2 text-dark mb-0">
                                Level Rewards
                              </h4>
                            </div>
                          </a>
                        </div>

                        {/* seventh slider */}
                        <div className="box px-0 py-6 wdt-193">
                          <a className="card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-sidebar-list-item text-decoration-none">
                            <img
                              src="images/crowdfunding.webp"
                              className="card-img-top"
                              alt="Apartment"
                              style={{ width: "140px" }}
                            />
                            <div className="card-body px-0 pt-5 pb-0">
                              <h4 className="card-title fs-16 lh-2 text-dark mb-0">
                              Crowdfunding
                              </h4>
                            </div>
                          </a>
                        </div>

                        
                        {/* eighth slider */}
                        <div className="box px-0 py-6 wdt-193">
                          <a className="card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-sidebar-list-item text-decoration-none">
                            <img
                              src="images/circleicon.png"
                              className="card-img-top"
                              alt="Apartment"
                              style={{ width: "140px" }}
                            />
                            <div className="card-body px-0 pt-5 pb-0">
                              <h4 className="card-title fs-16 lh-2 text-dark mb-0">
                              Top 10 circle income
                              </h4>
                            </div>
                          </a>
                        </div>

                        
                        {/* eighth slider */}
                        <div className="box px-0 py-6 wdt-193">
                          <a className="card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-sidebar-list-item text-decoration-none">
                            <img
                              src="images/Franchiseeincome.png"
                              className="card-img-top"
                              alt="Apartment"
                              style={{ width: "140px" }}
                            />
                            <div className="card-body px-0 pt-5 pb-0">
                              <h4 className="card-title fs-16 lh-2 text-dark mb-0">
                              Franchisee income
                              </h4>
                            </div>
                          </a>
                        </div>
                        
                        {/* eighth slider */}
                        <div className="box px-0 py-6 wdt-193">
                          <a className="card border-0 align-items-center justify-content-center pt-7 pb-5 px-3 shadow-hover-3 bg-transparent bg-sidebar-list-item text-decoration-none">
                            <img
                              src="images/workingincome.png"
                              className="card-img-top"
                              alt="Apartment"
                              style={{ width: "140px" }}
                            />
                            <div className="card-body px-0 pt-5 pb-0">
                              <h4 className="card-title fs-16 lh-2 text-dark mb-0">
                              Working income
                              </h4>
                            </div>
                          </a>
                        </div>
                      </Slider>
                    </div>
                  </AnimationOnScroll>

                  {/* slider start */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-fade newsletter-section">
          {/* style={{ backgroundImage: `url(${BackLineImage})` }} */}

          <div className="container container-xxl">
            <div className=" py-5 ">
              <div className="row flex-direction-column subscribe-section">
                <div className="col-md-6 subscribe-image">
                  <AnimationOnScroll animateIn="animate__fadeInLeft">
                    <img className="round-5 mt-2" src="images/mlm.png" />
                  </AnimationOnScroll>
                </div>

                <div className="col-md-6 text-center round-5 subscribe-letter">
                  <AnimationOnScroll animateIn="animate__fadeInRight">
                    <div className=" px-5 py-8 add-paddings-mobile">
                      <img
                        className="landing-fevicon mb-1"
                        src="images/aimtimebusiness.png"
                      />
                      <h2 className="display-2 mb-1 text-saffron fs-42 fw-600 ">
                        No.1 Platform for
                      </h2>
                      <h2 className="text-blue mb-1 display-2 text-saffron fs-42 fw-600 ">
                      Online Work From Home{" "}
                      </h2>
                      <h2 className="text-black mb-1">
                      Online Product Sell and Booking
                      </h2>
                      <p className="text-center text-black fs-17 fm-nunito">
                        We are always with you if you have face any issues!!
                      </p>
                      <form className="d-flex">
                        <div className="position-relative w-100">
                          <div className="in-fr">
                            <SearchLocationInput />
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* <iframe
                      className="iframe-land"
                      src="https://0516cd31.sibforms.com/serve/MUIEAPUc9Oc4p5WvdSJRmQoz-gubKbgJLtwwWOZ1UK4BcG4TZpu0Q_Zb4f5HgnTd8xB1FxD9242zHLLdNkHFpo4fcgt3LDX4zUKWzgFGgm4u9Bhx7UlFHF7lQIGgeRHsHVQVSao4hLA3nDoDQawAi2RkTXJLA30npd-jjXzLXPpIVxoZLvs-zWdb3T2nmY1kybfjhdtcltsDkTyq"
                      frameborder="0"
                      scrolling="no"
                      allowfullscreen
                    ></iframe> */}
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-lg-12 pb-lg-10 py-11 padding-top-bottom-50">
          <div className="container container-xxl text-center">
          <h2 className="text-heading font-size-24"></h2>
                    
                    <h2 className="text-heading fm-poppins fs-22 mb-5 margin-bottom-15">
                      We designed AimTime Business to be an inclusive and
                      accessible platform for anyone looking to secure financial
                      independence.
                    </h2>
                   
                    <a href="/mlm/subscription"
                  type="button" 
                  className="btn light-hover text-white bg-saffron  rounded-lg font-weight-bold text-uppercase"
                  style={{  padding: "10px" ,width: "350px"}}
                >
                  JOIN US
                </a>
               
                    
                   
          </div>
         </section>
        
        <section className="pt-lg-12 pb-lg-10 py-11 padding-top-bottom-50">
          {/* style={{ backgroundImage: `url(${BackImage})` }} */}
          <div className="container container-xxl">
            <div className="row flex-direction-column about-us-landing">
              <div className="col-md-6 add-max-width-100">
                <AnimationOnScroll animateIn="animate__fadeInLeft">
                  <div className="about-img-wrap">
                    <img className="about-img-one" src="images/directorimages.jpg" />
                  </div>
                </AnimationOnScroll>
              </div>
              <div className="col">
                <AnimationOnScroll animateIn="animate__fadeInRight">
                  <div className="ml-10 margin-left-0-about-us margin-left-0">
                    <h2 className="text-heading font-size-24">About Us</h2>
                    <span className="heading-divider divider-bottom"></span>
                    <h2 className="text-heading fm-poppins fs-22 mb-5 margin-bottom-15">
                    The investment Service Provide
                      investment Your valuable time and amount then get back extra.

                    </h2>
                    <p className="fs-17 fm-nunito text-justify">
                          AimTime Business is a pioneering online platform dedicated to providing individuals with the opportunity to achieve financial independence through passive income. Our innovative approach combines the simplicity of investment with the excitement of a Crowd Funding, offering a unique and rewarding experience.
                          This company was registered on 8 April 2019 from R.O.C. The directors of this company are Sanjay Kumar Singh and Nibha Devi. 

                    </p>
                    <div className="row">
                      <p className="fs-16 fw-600 fm-nunito text-justify">
                        <img
                          className="mr-1"
                          style={{ width: "18px" }}
                          src="images/check.png"
                        />
                        Ready to start earning passive income? Join AimTime Business and take the first step towards financial freedom. Our dedicated support team is always available to assist you and answer your questions.

                      </p>
                    </div>
                    <p className="fs-16 fw-600 fm-nunito text-justify">
                      <img
                        className="mr-1"
                        style={{ width: "18px" }}
                        src="images/check.png"
                      />
                      WHO WE ARE
                      : Our vision is to create a world where everyone has the power to achieve their financial goals without the constraints of traditional employment. We strive to be the leading platform for passive income, empowering individuals to live their dreams and build a better future.
                    </p>
                    <p className="fs-16 fw-600 fm-nunito text-justify">
                      <img
                        className="mr-1"
                        style={{ width: "18px" }}
                        src="images/check.png"
                      />
                      Our Mission: Our mission is to provide a secure, transparent, and user-friendly platform for individuals to invest and earn passive income. We are committed to fostering a community of like-minded individuals who share our passion for financial freedom. Through our innovative Crowd Funding and exceptional customer support, we aim to exceed the expectations of our members and deliver unparalleled value.

                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </section>


        {/* testimonial section add */}
        <section className="py-8 add-bg-color-more ">
          {/* style={{ backgroundImage: `url(${BackInImage})` }} */}
          <div className="container container-xxl">
            <div className="row heading-section-featured-agents mt-10">
              <div className="col-md-6">
                <h3 className="heading-testimonial text-blue ">
                  Why do people love us?
                </h3>
                <h2 className="text-heading mb-20">
                  What Our Invester Say About Us!
                </h2>
              </div>
            </div>
            <div
              className="slick-slider slick-dots-mt-0 custom-arrow-spacing-30 opacity-1"
              data-slick-options='{"slidesToShow": 4,"dots":true,"arrows":false,"responsive":[{"breakpoint": 1600,"settings": {"slidesToShow":3}},{"breakpoint": 992,"settings": {"slidesToShow":2,"arrows":false}},{"breakpoint": 768,"settings": {"slidesToShow": 2,"arrows":false,"dots":true,"autoplay":true}},{"breakpoint": 576,"settings": {"slidesToShow": 1,"arrows":false,"dots":true,"autoplay":true}}]}'
            >
              <Slider {...Agents}>
                {/* first slider */}

                {reviews.map((review) => (
                  <AnimationOnScroll
                    animateIn="animate__fadeInUp"
                    key={review.id}
                  >
                    <div className="box">
                      <div className="card p-6" data-animate="fadeInUp">
                        <div className="card-body p-0 text-center">
                          <span className="text-primary text-align fs-26 lh-1 mb-4 d-block">
                            <img src="images/quote-1.png" />
                          </span>
                          <p className="card-text fs-15 lh-2 mb-4">
                            {review.txt}
                          </p>
                          <span className="mx-auto divider mb-5"></span>
                          <img
                            src={review.image}
                            className="rounded-circle d-inline-block mb-2"
                            alt={review.name}
                            style={{ width: "200px;" }}
                          />
                          <p className="fs-16 lh-214 text-dark font-weight-500 mb-0">
                            Lydia Wise
                          </p>
                          <p className="mb-0">{review.designation}</p>
                        </div>
                      </div>
                    </div>
                  </AnimationOnScroll>
                ))}
              </Slider>
            </div>
          </div>
        </section>
      </main>

      <div className="position-fixed pos-fixed-bottom-right p-6 z-index-10">
        <a
          href="#"
          className="gtf-back-to-top text-white bg-blue hover-saffron sidebar-list-item bg shadow p-0 w-52px h-52 rounded-circle fs-20 d-flex align-items-center justify-content-center add-opacity-cstm"
          title="Back To Top"
        >
          <i className="fal fa-arrow-up"></i>
        </a>
      </div>
      <Footer />
    </>
  );
};
export default LandingPage;

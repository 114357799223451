import React, { useState, useEffect } from "react";
import axios from "axios";

const WinningListCard = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [lotteryData, setLotteryData] = useState([]);

  useEffect(() => {
    const fetchLotteryData = async () => {
      try {
        const response = await axios.get(`${API_URL}/lottery/card/winning/`);
        const sortedData = response.data.sort(
          (a, b) => new Date(b.create_at) - new Date(a.create_at)
        ); // Sort by date in descending order
        setLotteryData(sortedData);
      } catch (error) {
        console.error("Error fetching the lottery data:", error);
      }
    };
    fetchLotteryData();
  }, []);

  return (
    <div className="container mt-5">
      <h2 className="text-center">Product sell and booking Date & Time</h2>
      <div className="table-responsive">
        <table className="table table-bordered table-striped mt-4">
          <thead className="thead-dark">
            <tr>
              <th>#</th>
              <th>Product Name</th>
              <th>Product Number</th>
              <th>Date and Time</th>
              {/* <th>Lottery Date Time</th> */}
            </tr>
          </thead>
          <tbody>
            {lotteryData.length > 0 ? (
              lotteryData.map((card, index) => (
                <tr key={card.id}>
                  <td>{index + 1}</td>
                  <td>{card.lottery_card_name}</td>
                  <td>{card.lottery_card_number}</td>
                  <td>{new Date(card.create_at).toLocaleString()}</td>
                  {/* <td>{new Date(card.lottery_start_date).toLocaleString()} - {new Date(card.lottery_end_date).toLocaleString()}</td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  No data available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WinningListCard;

import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiUrl, getToken } from "../../../utils/common";
import { Link } from "react-router-dom";


const ChangePassword = () => {

    // change password api
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const UpdatePassword = () => {
    
        const data = {};
        data.old_password = oldPassword;
        data.new_password1 = newPassword;
        data.new_password2 = confirmPassword;

        const option = {
            method: 'PUT', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            data: JSON.stringify(data),
            url: `${ApiUrl()}/auth/user/change-password`
        }

        axios(option)
        .then(e => {
            const data = JSON.parse(e?.data?.data || '{}');
            toast.success("Password Change Sucessfull");
            navigate('/devmyprofile');

        })
        .catch(err => {
            toast.error(err?.response?.data?.non_field_errors[0] || err?.response?.data?.non_field_errors[1])
        })
    }

    return (
        <>
            <div class="form-body">
            <div class="website-logo">
            <Link to="/">
                <div class="logo">
                <img className="logo-size" src="images/dpf-logo.png" alt="HomeID" style={{width:'150px'}} />
                </div>
            </Link>
        </div>
        
        <div className="img-holder">
                <div className="bg" style={{ backgroundImage: "url(images/skyline-2.png)" }}></div>
                <div className="info-holder">

                </div>
            </div>
            <div className="form-holder">
                <div className="form-content form-ch-content">
                    <div className="form-items">
                    <h3 className="ti-fs"><span className="pl-name">AimTime Business</span> Always Secure your Credentials.</h3>
                        <p>Access to the most powerfull tool in the entire AimTime Business Platform.</p>
                            <h3 className="card-title mb-0 text-heading lh-15">Change password</h3>

                            <div className="form-group">
                                <label htmlFor="oldPassword" className="text-heading">Old Password</label>
                                <input type="password" className="form-control form-control-lg border-0" id="oldPassword"
                                    name="oldpassword" onChange={(e) => setOldPassword(e?.target?.value)} value={oldPassword} />
                            </div>
                            <div className="form-row mx-n4">
                                <div className="form-group col-md-6 col-lg-12 col-xxl-6 px-4">
                                    <label htmlFor="newPassword" className="text-heading">New Password</label>
                                    <input type="password" className="form-control form-control-lg border-0" id="newPassword"
                                        name="newpassword" onChange={(e) => setNewPassword(e?.target?.value)} value={newPassword} />
                                </div>
                                <div className="form-group col-md-6 col-lg-12 col-xxl-6 px-4">
                                    <label htmlFor="confirmNewPassword" className="text-heading">Confirm New Password</label>
                                    <input type="password" className="form-control form-control-lg border-0"
                                        id="confirmNewPassword" name="confirmnewpassword" onChange={(e) => setConfirmPassword(e?.target?.value)} value={confirmPassword} />
                                </div>

                                <div className="form-group col-md-6 col-lg-12 col-xxl-6 px-4">
                                    <button type='button' className='btn text-white bg-blue hover-saffron' onClick={UpdatePassword}>Update Password</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </>
    )
}
export default ChangePassword;
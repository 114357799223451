import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../Components/header/header";
import Footer from "../../Components/footer/footer";

const TermsCondition = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [termCondition, setTermsCondition] = useState(null);

  useEffect(() => {
    const fetchTermsCondition = async () => {
      try {
        const response = await axios.get(`${API_URL}/blog/terms/condition/`);
        setTermsCondition(response.data);
      } catch (error) {
        console.error("Error fetching terms and conditions:", error);
      }
    };

    fetchTermsCondition();
  }, [API_URL]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Header />
      <br />
      <br />

      <br />

      <br />

      <br />


      <br />

      <div style={{ marginTop: "100px" }}>
        <main id="content" className="mt-100">
          <section className="bg-patten-03 bg-gray-01 pb-13" data-animated-id="3">
            <div className="container">
              <div className="card border-0 mt-n13 z-index-3 mb-12">
                <div className="card-body p-6 px-lg-14 py-lg-10">
                  <p className="text-uppercase text-blue mb-5 fw-600 text-center fs-22">
                    Terms and Conditions
                  </p>
                  <div className="details-section text-secondary">
                    {termCondition ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: termCondition.description,
                        }}
                      />
                    ) : (
                      <p className="text-center text-black fs-16 fm-nunito">
                        Loading terms and conditions...
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default TermsCondition;

import axios from "axios";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import GoogleLogin from "react-google-login";
import { toast } from "react-toastify";
import { ApiUrl } from "../../../utils/common";
import Popup from "../../popup/signinpopup/popup";
import { Link } from "react-router-dom";

const StripSignUp = () => {
    const [buttonPopup, setButtonPopup] = useState(false);
    const [fullName, setFullName] = useState('');
    const [userName, setUserName] = useState('');
    const [passWord, setPassWord] = useState('');
    const [confirmPassword, serConfirmPassword] = useState('');
    const [rIsShowPassword, setRIsShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [developerField, setDeveloperField] = useState(false);
    const [agentField, setAgentField] = useState(false);
    const [otherBusinessField, setOtherBusinessField] = useState(false);

    const [businessCheck, setBusinessCheck] = useState(false);
    const [nonBusiness, setNonBusiness] = useState(false);

    const StripeSignUpSubmit = () => {
        if (!ValidateRegisters()) return;
        if (nonBusiness || businessCheck && developerField || agentField || otherBusinessField) {
            const data = {};
            data.name = fullName;
            data.email = userName;
            data.password = passWord;
            data.re_password = confirmPassword;
            data.is_developer = developerField;
            data.is_agent = agentField;
            data.is_otherbusiness = otherBusinessField;

            const option = {
                method: 'POST', headers: {
                    'access-control-allow-origin': '*',
                    'content-type': 'application/json',
                },

                data: JSON.stringify(data),
                url: `${ApiUrl()}`
            };

            axios(option)
                .then(e => {

                    const data = JSON.parse(e?.data?.data || '{}');
                    toast.success(e?.data?.success);
                    setButtonPopup(true);
                })
                .catch(err => {
                    toast.error(err?.response?.data?.error);
                });
        }
        else {
            toast.error("Select Business Type")
            return;
        }



    }
    const ValidateRegisters = () => {
        if (!fullName || fullName.trim() === "") {
            toast.error("Please Enter FullName")
            return false;
        }

        if (!userName || userName.trim() === "") {
            toast.error("Please Enter Your UserName / Email")
            return false;
        }

        if (!passWord) {
            toast.error("Please Enter Your Password")
            return false;
        }

        if (!confirmPassword) {
            toast.error("Please Enter Same PassWord As Above")
            return false;
        }
        return true;
    }
    const ClientId = "128217884016-2rfb0n7ljilmtsb6onvroq6ikp4obklu.apps.googleusercontent.com";

    const responsegoogle = (res) => {
        if (nonBusiness || developerField || agentField || otherBusinessField) {
            if (res?.accessToken) {
                const option = {
                    method: 'POST', headers: {
                        'access-control-allow-origin': '*',
                        'content-type': 'application/json',
                    },
                    data: {
                        token: res.accessToken,
                        is_developer: developerField,
                        is_agent: agentField,
                        is_otherbusiness: otherBusinessField,
                    },

                    url: `${ApiUrl()}/auth/user/google_login`
                };

                axios(option)
                    .then(e => {
                        // toast.success(e?.data?.success);
                    })
                    .catch(err => {

                    })
            }
        } else {
            toast.error("Please Select Whether You are Business Entity")
            return;
        }
    }
    return (
        <>
            <div class="form-body">
                <div class="website-logo">
                    <Link to="/">
                        <div class="logo">
                            <img className="logo-size" src="images/dpf-logo.png" alt="HomeID" style={{ width: '150px' }} />
                        </div>
                    </Link>
                </div>
                <div className="img-holder">
                    <div className="bg" style={{ backgroundImage: "url(images/skyline-2.png)" }}></div>
                    <div className="info-holder">

                    </div>
                </div>
                <div className="form-holder">
                    <div className="form-content form-reg-content">
                        <div className="form-items">
                            <h3 className="ti-fs t-aling-c"><span className="pl-name">Sign Up</span></h3>
                            {/* <p>Access to the most powerfull tool in the entire AimTime Business Platform.</p> */}

                            {/*  <h2 className="card-title fs-30 font-weight-600 text-dark lh-16 mb-2">Sign Up</h2> */}
                            {/* <form className="form"> */}

                            <div className="form-group mb-4">
                                <label htmlFor="full-name" className="sr-only">Full name</label>
                                <div className="input-group input-group-lg">
                                    <div className="input-group-prepend ">
                                        <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                                            <i className="far fa-address-card"></i></span>
                                    </div>
                                    <input type="text" className="form-control border-0 shadow-none fs-13"
                                        id="full-name" name="full-name"
                                        placeholder="Full name" onChange={(e) => setFullName(e?.target?.value)} value={fullName} />
                                </div>
                            </div>
                            {/* username details */}
                            <div className="form-group mb-4">
                                <label htmlFor="username01" className="sr-only">Username</label>
                                <div className="input-group input-group-lg">
                                    <div className="input-group-prepend ">
                                        <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                                            <i className="far fa-user"></i></span>
                                    </div>
                                    <input type="text" className="form-control border-0 shadow-none fs-13"
                                        id="username01" name="username01"
                                        placeholder="Username / Your email" onChange={(e) => setUserName(e?.target?.value)} value={userName} />
                                </div>
                            </div>

                            {/* password fields */}
                            <div className="form-group mb-4">
                                <label htmlFor="password01" className="sr-only">Password</label>
                                <div className="input-group input-group-lg">
                                    <div className="input-group-prepend ">
                                        <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                                            <i className="far fa-lock"></i>
                                        </span>
                                    </div>
                                    <input type={rIsShowPassword ? "text" : "password"} className="form-control border-0 shadow-none fs-13"
                                        id="password01" name="password01"
                                        placeholder="Password" onChange={(e) => setPassWord(e?.target?.value)} value={passWord} />
                                    <div className="input-group-append">
                                        <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                                            <i className="far fa-eye-slash" onClick={() => setRIsShowPassword(!rIsShowPassword)}></i>
                                        </span>
                                    </div>
                                </div>
                                {/* <p className="form-text">Password must be at least 8 characters in length</p> */}
                            </div>

                            {/* confirm password */}
                            <div className="form-group mb-4">
                                <label htmlFor="password02" className="sr-only">Confirm Password</label>
                                <div className="input-group input-group-lg">
                                    <div className="input-group-prepend ">
                                        <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                                            <i className="far fa-lock"></i>
                                        </span>
                                    </div>
                                    <input type={showConfirmPassword ? "text" : "password"} className="form-control border-0 shadow-none fs-13"
                                        id="password02" name="password02"
                                        placeholder="Confirm Password" onChange={(e) => serConfirmPassword(e?.target?.value)} value={confirmPassword} />
                                    <div className="input-group-append">
                                        <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                                            <i className="far fa-eye-slash" onClick={() => setShowConfirmPassword(!showConfirmPassword)}></i>
                                        </span>
                                    </div>
                                </div>
                                <p className="form-text">it should be same as above password</p>
                            </div>

                            <div className="main-section-checkbox-register">
                                <Form.Check
                                    inline
                                    label="Business"
                                    name="group1"
                                    type={'radio'}
                                    id={`inline-${'radio'}-1`}
                                    // onChange={() => setDeveloperField(!developerField)} value={developerField}

                                    onClick={() => setBusinessCheck(true)}

                                />

                                <Form.Check
                                    inline
                                    label="Non Business"
                                    name="group1"
                                    type={'radio'}
                                    id={`inline-${'radio'}-1`}
                                    onChange={(e) => setNonBusiness(!nonBusiness)} value={nonBusiness}
                                    onClick={() => setBusinessCheck(false)}
                                // onChange={() => setAgentField(!agentField)} value={agentField}
                                />
                            </div>

                            {
                                businessCheck ? <div className="main-section-select-radios">
                                    <p className="form-text mb-0 fw-600">For Business user, select one of the options below to Signup</p>

                                    <div className="main-section-checkbox-register">
                                        <Form.Check
                                            inline
                                            label="Developer"
                                            name="group3"
                                            type={'radio'}
                                            id={`inline-${'radio'}-3`}
                                            onChange={() => setDeveloperField(!developerField)} value={developerField}


                                        />

                                        <Form.Check
                                            inline
                                            label="Agent"
                                            name="group3"
                                            type={'radio'}
                                            id={`inline-${'radio'}-3`}
                                            onChange={() => setAgentField(!agentField)} value={agentField}
                                        />

                                        <Form.Check
                                            inline
                                            label="Other Business"
                                            name="group3"
                                            type={'radio'}
                                            id={`inline-${'radio'}-3`}
                                            // checked="checked"
                                            onChange={() => setOtherBusinessField(!otherBusinessField)} value={otherBusinessField}
                                        />
                                    </div>
                                </div> : null
                            }
                            <button type="button" className="btn bg-blue text-white hover-saffron btn-lg btn-block" onClick={() => StripeSignUpSubmit() }>Sign up</button>
                            {/* </form> */}

                            <div className="divider text-center my-2">
                                <span className="px-4 bg-white lh-17 text">
                                    or continue with
                                </span>
                            </div>

                            <div className="col-12 px-2 mb-4">
                                <GoogleLogin className="button-google-login"
                                    clientId={ClientId}
                                    buttonText="Google"
                                    onSuccess={responsegoogle}
                                    onFailure={responsegoogle}
                                    cookiePolicy={'single_host_origin'}
                                    isSignedIn={false}
                                />
                            </div>

                            {/*  <p className="lg-bo">Already have an account? <a href="/login" className="text-heading hover-primary"><u>Log
                                            in</u></a></p> */}

                        </div>
                    </div>
                </div>
            </div>

            {/* popup section */}
            {/* <Popup trigger={buttonPopup} setTrigger={setButtonPopup} className="login-popup">
                <h2 className="heading-inner-modal"><span>Verification</span> email sent. please check</h2>

                <div className="section-before-google-login">
                    <button type="button" className="btn btn-primary" onClick={ResendMail} >Resend Mail</button>
                </div>
            </Popup> */}
        </>
    )
}

export default StripSignUp;